<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Edit Users</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <template v-if="pageReady">
                                    <div class="grid">
                                        <div class="col-12">
                                            <form ref="observer"  tag="form" @submit.prevent="submitForm()">
                                                <!--[form-content-start]-->
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Company *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlcompany" v-model.trim="formData.company"  label="Company" type="text" placeholder="Enter Company"      
                                                                class=" w-full" :class="getErrorClass('company')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('company')" class="p-error">{{ getFieldError('company') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Name *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlname" v-model.trim="formData.name"  label="Name" type="text" placeholder="Enter Name"      
                                                                class=" w-full" :class="getErrorClass('name')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('name')" class="p-error">{{ getFieldError('name') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Username *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <check-duplicate v-model="formData.username" check-path="components_data/users_username_exist/" v-slot="checker">
                                                                <InputText ref="ctrlusername" @blur="checker.check" :loading="checker.loading" v-model.trim="formData.username"  label="Username" type="text" placeholder="Enter Username"      
                                                                class=" w-full" :class="getErrorClass('username')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('username')" class="p-error">{{ getFieldError('username') }}</small> 
                                                                <small v-if="!checker.loading && checker.exist" class="p-error"> Not available</small>
                                                                <small v-if="checker.loading" class="text-500">Checking...</small>
                                                                </check-duplicate>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Active *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlactive" v-model.trim="formData.active"  label="Active" type="text" placeholder="Enter Active"      
                                                                class=" w-full" :class="getErrorClass('active')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('active')" class="p-error">{{ getFieldError('active') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Datecreate *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <Calendar :showButtonBar="true" :showTime="true" dateFormat="yy-mm-dd" hourFormat="24" v-model="formData.datecreate" :showIcon="true" class="w-full" :class="getErrorClass('datecreate')"     mask="yy-MM-DD HH:mm"     />
                                                                <small v-if="isFieldValid('datecreate')" class="p-error">{{ getFieldError('datecreate') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Role *
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlrole" v-model.trim="formData.role"  label="Role" type="text" placeholder="Enter Role"      
                                                                class=" w-full" :class="getErrorClass('role')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('role')" class="p-error">{{ getFieldError('role') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                User Role Id 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <api-data-source   api-path="components_data/role_id_option_list" >
                                                                    <template v-slot="req">
                                                                        <Dropdown class="w-full" :class="getErrorClass('user_role_id')"   :loading="req.loading"   optionLabel="label" optionValue="value" ref="ctrluser_role_id"  v-model="formData.user_role_id" :options="req.response" label="User Role Id"  placeholder="Select a value ..." >
                                                                        </Dropdown> 
                                                                        <small v-if="isFieldValid('user_role_id')" class="p-error">{{ getFieldError('user_role_id') }}</small> 
                                                                    </template>
                                                                </api-data-source>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--[form-content-end]-->
                                                <div v-if="showSubmitButton" class="text-center my-3">
                                                    <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="loading">
                                    <div class="p-3 text-center">
                                        <ProgressSpinner style="width:50px;height:50px" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, numeric, } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useEditPage } from '@/composables/editpage.js';
	const props = defineProps({
		id: [String, Number],
		pageName: {
			type: String,
			default: 'users',
		},
		routeName: {
			type: String,
			default: 'usersedit',
		},
		pagePath: {
			type : String,
			default : 'users/edit',
		},
		apiPath: {
			type: String,
			default: 'users/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		msgAfterUpdate: {
			type: String,
			default: "Record updated successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	const formDefaultValues = {
		company: "", 
		name: "", 
		username: "", 
		active: "", 
		datecreate: "", 
		role: "", 
		user_role_id: "", 
	}
	const formData = reactive({ ...formDefaultValues });
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterUpdate);
		if(props.redirect) app.navigateTo(`/users`);
	}
	const rules = computed(() => {
		return {
			company: { required },
			name: { required },
			username: { required },
			active: { required },
			datecreate: { required },
			role: { required },
			user_role_id: { numeric }
		}
	});
	const v$ = useVuelidate(rules, formData); //form validation
	const page = useEditPage(props, formData, v$, onFormSubmited);
	//page state
	const {
		submitted, // form api submitted state - Boolean
		saving, // form api submiting state - Boolean
		loading, // form data loading state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page computed propties
	const {
		apiUrl, // page current api path
		currentRecord, // current page record  - Object
	} = page.computedProps
	//page methods
	const { 
		load, // get editable form data from api
		submitForm, // submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname)
		getFieldError, //  get validation error message - args(fieldname)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Edit Users";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
